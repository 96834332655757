import './App.css';
import 'devextreme/dist/css/dx.light.css';
import 'devexpress-richedit/dist/dx.richedit.css';
import React, {lazy, Suspense} from 'react';
import {Route, Routes} from 'react-router-dom';


const Default = lazy(() => import("./components/default/Default"));
const Simple = lazy(() => import("./components/simple/Simple"));
const Readonly = lazy(() => import("./components/readonly/Readonly"));

function App() {
    return <Suspense fallback="">
        <Routes>
            <Route path="/" element={<Default />} />
            <Route path="/simple" element={<Simple />} />
            <Route path="/readonly" element={<Readonly />} />
        </Routes>
    </Suspense>
}

export default App;